import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import { trustedPartners } from '@/services/homePage.service';

const TrustedPartners = () => {
  const [isMobile] = useIsMobile();

  return (
    <div className='mb-5 md:mb-2'>
      <Text
        {...{
          className:
            'text-light-silver text-xs md:text-xl font-medium uppercase text-center top-brands-header-web',
          content: 'Trusted Venue Partners'
        }}
      />
      <div className='relative overflow-hidden bg-gray-100 py-5 w-full'>
        <div className='flex w-max animate-marquee'>
          <div className='flex space-x-6 md:space-x-16'>
            {trustedPartners.map(({ alt, url }, index) => (
              <div
                key={index}
                className='relative'
              >
                <Image
                  alt={alt}
                  className=''
                  height={isMobile ? 40 : 80}
                  layout='fixed'
                  objectFit='contain'
                  priority
                  src={`${staticMediaStoreBaseURL}${url}`}
                  width={isMobile ? 80 : 160}
                />
              </div>
            ))}
            <div className='flex space-x-6 md:space-x-16'>
              {trustedPartners.map(({ alt, url }, index) => (
                <div
                  key={index}
                  className='relative'
                >
                  <Image
                    alt={alt}
                    className=''
                    height={isMobile ? 40 : 80}
                    layout='fixed'
                    objectFit='contain'
                    priority
                    src={`${staticMediaStoreBaseURL}${url}`}
                    width={isMobile ? 80 : 160}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedPartners;
