import { Text, TopBrandsRow } from '@/components/atomic/atoms';
import { splitArrayIntoChunks } from '@/services/homePage.service';

const chunkedBlankRows = splitArrayIntoChunks({
  chunkSize: 2
});

const chunkedLogos = splitArrayIntoChunks({
  chunkSize: 4
});

const BlankRowAndCells = () => (
  <tr>
    {chunkedBlankRows.map(
      ({ logoURL }, index) => index > 1 && <td key={`${logoURL}-${index}`}></td>
    )}
  </tr>
);

const TopBrandsMobile = () => (
  <div className='top-brands-header-mobile bg-white'>
    <div className='block'>
      <Text
        {...{
          className:
            'text-light-silver text-xs md:text-xl font-medium uppercase text-center top-brands-header-mobile md:top-brands-header-web',
          content: 'Trusted by top brands'
        }}
      />
      <div className='flex items-center justify-center justify-self-center justify-items-center mt-2 md:mt-0 pb-7'>
        <table className='border-collapse bg-white table-container'>
          <tbody>
            <BlankRowAndCells />
            {chunkedLogos.map((renderRow, index) => (
              <TopBrandsRow
                key={`${index}-${Math.random()}`}
                {...{ renderRow }}
              />
            ))}
            <BlankRowAndCells />
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default TopBrandsMobile;
