import Image from 'next/image';
import Link from 'next/link';

import { TextKeyValuePair } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const trendingServices = [
  {
    alt: 'corportate event image',
    cardImg: 'corporate-events.jpg',
    subTitle: 'Effortless event solutions',
    title: 'CORPORATE EVENTS',
    url: 'https://corporate.hafla.com?nocache=1'
  },
  {
    alt: 'wedding engagement image',
    cardImg: 'wedding-event.jpg',
    subTitle: 'Celebrate Love in Style',
    title: 'WEDDINGS & ENGAGEMENT',
    url: 'https://weddings.hafla.com?nocache=1'
  }
];

const TrendingServices = () => {
  const [isMobile] = useIsMobile();

  return (
    <div className='flex grid grid-cols-1 md:grid-cols-2 place-items-center justify-self-center gap-4 md:gap-6'>
      {trendingServices.map(({ alt, cardImg, subTitle, title, url }) => (
        <Link
          href={url}
          key={title}
        >
          <a
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className='relative w-full justify-self-center'>
              <div className='relative gradient-container'>
                <div className='z-10 bottom-overlay inner-content gradient-border hover:shadow-lg'>
                  <Image
                    alt={alt}
                    height={isMobile ? 376 : 640}
                    objectFit='contain'
                    priority={true}
                    src={`${staticMediaStoreBaseURL}/home-page/${cardImg}`}
                    width={isMobile ? 320 : 545}
                  />
                </div>
              </div>
              <div className='flex flex-col gap-1 md:gap-2 absolute bottom-8 md:bottom-10 z-50 left-5 md:left-6'>
                <TextKeyValuePair
                  {...{
                    className:
                      'flex-col trending-services-title-mobile subpixel-antialiased',
                    label: title,
                    labelClass:
                      'uppercase text-lg md:text-2xl font-semibold text-white',
                    spaceTop: '',
                    value: subTitle,
                    valueClassName:
                      'trending-services-sub-title-mobile md:!text-base font-medium italic text-white md:pb-0 pb-3'
                  }}
                />

                <Image
                  alt='get started icon'
                  className='cursor-pointer'
                  height={isMobile ? 22 : 56}
                  layout='fixed'
                  src={`${staticMediaStoreBaseURL}/icons/get-started.svg`}
                  width={isMobile ? 100 : 178}
                />
              </div>
            </div>
          </a>
        </Link>
      ))}
    </div>
  );
};

export default TrendingServices;
