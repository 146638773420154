import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text, TextKeyValuePair } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  captureGTMEventCTAClick,
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME
} from '@/lib/gtm';

const BecomePartnerWeb = () => {
  const router = useRouter();
  return (
    <div className='relative w-full bg-become-partner-background bg-cover'>
      <div className='inset-0 flex flex-col md:flex-row'>
        <div className='flex-1 flex flex-col justify-center items-center text-white'>
          <div className='flex flex-col items-baseline'>
            <TextKeyValuePair
              {...{
                className: 'flex flex-col gap-one',
                label: 'Become a Partner',
                labelClass:
                  'text-3xl sm:text-2xl md:text-3xl font-Montserrat font-bold',
                spaceTop: '',
                value: '3 easy steps to join the Hafla!',
                valueClassName:
                  'text-lg sm:text-base md:text-lg font-Montserrat mb-8'
              }}
            />
            <div
              className={`flex flex-row justify-evenly text-center uppercase cursor-pointer bottom-6 md:bottom-18 z-10 py-2 px-4 sm:py-3 sm:px-6 rounded-full bg-white md:w-48 mr-20`}
              onClick={() => {
                captureGTMEventCTAClick({
                  ctaName: GTM_EVENTS_CTA_NAME.BECOME_PARTNER,
                  pageName: PAGE_NAME.HOME_PAGE.label
                });
                router.push('/partners/basic-info');
              }}
            >
              <Text
                {...{
                  className:
                    'font-google-basic text-15px font-medium text-black self-center tracking-widest',
                  content: 'Start here'
                }}
              />
              <div className='self-center'>
                <Image
                  alt='arrow right'
                  height={10}
                  layout='fixed'
                  src={`${staticMediaStoreBaseURL}/icons/arrowright.svg`}
                  width={14}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex-1 flex flex-col justify-center items-center text-white p-4 sm:p-6 md:p-8'>
          <Image
            alt='Hands with Puzzle'
            className='w-24 sm:w-28 md:w-32 h-auto'
            height={328}
            src={`${staticMediaStoreBaseURL}/home-page/become-partner.svg`}
            width={466}
          />
        </div>
      </div>
    </div>
  );
};

export default BecomePartnerWeb;
