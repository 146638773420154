import { Card, CollectionHeader } from '@/components/atomic/molecules';

const decodeHTMLEntities = ({ text }) => text
  .replace(/&#8217;/g, '’')
  .replace(/&#8220;/g, '“')
  .replace(/&#8221;/g, '”')
  .replace(/&#38;/g, '&')
  .replace(/&#39;/g, "'")
  .replace(/&#34;/g, '"')
  .replace(/&#45;/g, '-')
  .replace(/&#8211;/g, '–');

const HaflaBlogOrganism = ({ posts = [] }) => (
  <section className='py-7 md:py-15.5 px-5 md:px-20'>
    <CollectionHeader
      {...{
        buttonText: 'View all',
        buttonUrl: '/blog',
        collections: posts,
        subTitle: 'Stay updated with the latest news.',
        title: 'the hafla blog'
      }}
    />
    <div className='flex flex flex-row items-center justify-between mt-10'>
      {posts.map(({ id, yoast_head_json: yoastHeadJson, title, link }) => (
        <Card
          key={id}
          {...{
            imgSrc: yoastHeadJson?.og_image[0]?.url || '',
            linkUrl: link,
            title: decodeHTMLEntities({ text: title?.rendered || '' }),
            type: 'blog'
          }}
        />
      ))}
    </div>
  </section>
);

export default HaflaBlogOrganism;
