import Image from 'next/image';
import Link from 'next/link';

import { SectionHeading, Text } from '@/components/atomic/atoms';
import {
  CollectionHeader,
  SectionActionButton
} from '@/components/atomic/molecules';
import { mediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const TrendingCollectionList = ({ collectionList, isMobile }) => (
  <div className='flex trending-collection-container place-items-center justify-self-center sm:px-5 sm:ml-5 md:gap-6 mt-3 md:mt-10'>
    {collectionList.map(({ id, name, bannerImageUrl, slug }) => (
      <Link
        href={`/collections/${slug}`}
        key={id}
      >
        <a
          rel='noopener noreferrer'
          target='_blank'
        >
          <div className='relative trending-collection-tile justify-self-center'>
            <div className='relative gradient-container'>
              <div className='z-10 bottom-overlay inner-content gradient-border hover:shadow-lg'>
                <Image
                  alt='collection banner'
                  height={isMobile ? 326 : 412}
                  objectFit='fill'
                  priority={true}
                  src={`${mediaStoreBaseURL}/${bannerImageUrl}`}
                  width={isMobile ? 280 : 354}
                />
              </div>
            </div>
            <div className='flex flex-col gap-1 md:gap-2 absolute bottom-6 md:bottom-5 z-50 left-4 md:left-3'>
              <div className='flex-col trending-services-title-mobile subpixel-antialiased'>
                <Text
                  {...{
                    className: 'text-2xl font-bold text-white',
                    content: name
                  }}
                />
              </div>
            </div>
          </div>
        </a>
      </Link>
    ))}
  </div>
);

const TrendingCollectionOrganism = ({ collectionList }) => {
  const [isMobile] = useIsMobile();

  return (
    <div className='pt-5 md:py-10 md:px-20 md:mb-5'>
      {isMobile ? (
        <SectionHeading
          {...{
            letterSpacing: '3.6px',
            subTitle: 'Our top picks for the season.',
            title: 'Trending Collections',
            underline: true
          }}
        />
      ) : (
        <CollectionHeader
          {...{
            buttonText: 'View all',
            buttonUrl: '/collections',
            isOpenInSamePage: true,
            subTitle: 'Our top picks for the season.',
            title: 'Trending Collections'
          }}
        />
      )}
      <TrendingCollectionList {...{ collectionList, isMobile }} />
      {isMobile && (
        <div className='mt-5'>
          <SectionActionButton
            {...{
              buttonText: 'view all',
              isOpenInSameTab: true,
              ribbonButtonClass:
                'ribbon-button-styles view-all-button-container',
              url: '/collections'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TrendingCollectionOrganism;
