import {
  IconMobile,
  SectionHeading,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { SectionActionButton } from '@/components/atomic/molecules';
import { planEventWithHebaURL, staticMediaStoreBaseURL } from '@/config/common';
import { whyChooseHaflaServices } from '@/services/homePage.service';

const BottomHeader = () => (
  <div className='flex flex-row mt-4 Montserrat px-5'>
    <TextKeyValuePair
      {...{
        className:
          'flex-col self-center text-lg why-choose-hafla-mobile-header',
        label: `UAE's largest event services team with 17,000+ events enabled`,
        labelClass: 'font-extrabold',
        value: `Whether it's a gala dinner or a wedding ceremony, you need a venue or an
        artist, Hafla will help you get it with no stress..`,
        valueClassName: 'pt-1'
      }}
    />
  </div>
);

const WhyChooseHaflaMobileOrganism = () => (
  <div className='mt-5 text-center'>
    <SectionHeading
      title='WHY CHOOSE HAFLA'
      underline
    />
    <BottomHeader />
    <div className='flex flex-row flex-wrap items-start w-full mt-5 mb-5 justify-evenly'>
      {whyChooseHaflaServices.map(({ id, image, name }) => (
        <IconMobile
          key={id}
          {...{
            alt: name,
            className: 'img-center h-20 w-20',
            title: name,
            url: `${staticMediaStoreBaseURL}${image}`
          }}
        />
      ))}
    </div>
    <SectionActionButton
      {...{
        buttonText: 'PLAN YOUR EVENT',
        isOpenInSameTab: false,
        url: planEventWithHebaURL
      }}
    />
  </div>
);

export default WhyChooseHaflaMobileOrganism;
