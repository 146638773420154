import { Icon, SectionHeading } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { captureGTMEventBrowseCategoriesClick } from '@/lib/gtm';
import { featuredCategories } from '@/services/homePage.service';

const RenderCategoryIcon = () =>
  featuredCategories.map(({ name, href, logoUrl }, id) => (
    <div
      className='bounce-animation'
      key={id}
    >
      <Icon
        alt={name}
        anchorTagClass='relative'
        className='rounded-lg'
        containerClass='shadow-none filter hover:rounded-lg hover:shadow-2xl gradient-border-featured-categories'
        href={href}
        imageHeight={188}
        imageWidth={172}
        onClick={() => captureGTMEventBrowseCategoriesClick({ name })}
        title={name}
        titleClassName='font-family:Brandon Text items-center w-40 font-semibold mt-2 mb-5'
        url={`${staticMediaStoreBaseURL}${logoUrl}`}
      />
    </div>
  ));

const FeaturedCategoriesWeb = () => (
  <div className='relative flex flex-col items-center mt-10 pb-10'>
    <SectionHeading
      {...{
        letterSpacing: '7.7px',
        title: 'Explore Services',
        titleClassName:
          'text-center tracking-widest uppercase text-neutral-800 font-basic font-normal mb-8',
        underline: false
      }}
    />
    <div className='grid grid-cols-6 gap-4 px-4 mt-10'>
      <RenderCategoryIcon />
    </div>
  </div>
);

export default FeaturedCategoriesWeb;
