import { SectionHeading } from '@/components/atomic/atoms';
import { CardListMobile } from '@/components/atomic/molecules';

const HaflaBlogsMobileOrganism = ({ posts = [] }) => (
  <div className='bg-neutral pt-4 pb-10'>
    <SectionHeading
      title='Hafla Blog'
      underline
    />
    <CardListMobile
      {...{
        cardList: posts,
        imageBasePath: '',
        isViewButtonVisible: true,
        viewButtonLink: '/blog'
      }}
    />
  </div>
);

export default HaflaBlogsMobileOrganism;
