import _ from 'lodash';

const topBrandLogos = [
  {
    alt: 'Amazon',
    logoURL: 'Amazon.png'
  },
  {
    alt: 'BCG',
    logoURL: 'BCG.png'
  },
  {
    alt: 'Collines Aerospace',
    logoURL: 'Collines Aerospace.png'
  },
  {
    alt: 'DAMAC',
    logoURL: 'DAMAC.png'
  },
  {
    alt: 'DWTC',
    logoURL: 'DWTC.png'
  },
  {
    alt: 'EIF',
    logoURL: 'EIF.png'
  },
  {
    alt: 'Emaar',
    logoURL: 'Emaar.png'
  },
  {
    alt: 'Ford',
    logoURL: 'Ford.png'
  },
  {
    alt: 'Google',
    logoURL: 'Google.png'
  },
  {
    alt: 'Pirelli',
    logoURL: 'Pirelli.png'
  },
  {
    alt: 'Samsung',
    logoURL: 'Samsung.png'
  },
  {
    alt: 'Shein',
    logoURL: 'Shein.png'
  },
  {
    alt: 'Shell',
    logoURL: 'Shell.png'
  },
  {
    alt: 'Solana',
    logoURL: 'Solana.png'
  },
  {
    alt: 'Talabat',
    logoURL: 'Talabat.png'
  },
  {
    alt: 'Total Energies',
    logoURL: 'Total Energies.png'
  }
];

export const howDoesItWorkStepList = [
  {
    contentDetail: {
      body: [
        'Browse, over 10K products, services,',
        'packages, and exclusive deals!'
      ],
      title: 'Get Inspired'
    },
    image: {
      alt: 'Get Inspired',
      name: 'hdw-1.svg'
    },
    progressStatus: 25
  },
  {
    contentDetail: {
      body: [
        'Need help? Live chat with event experts',
        'for a personalized experience.'
      ],
      title: 'Consult with Experts'
    },
    image: {
      alt: 'Consult with Experts',
      name: 'hdw-2.svg'
    },
    progressStatus: 50
  },
  {
    contentDetail: {
      body: [
        'Select your items, add-to-cart, and pay',
        'easily with smart checkout methods.'
      ],
      title: 'Book your Hafla'
    },
    image: {
      alt: 'Book your Hafla',
      name: 'hdw-3.svg'
    },
    progressStatus: 75
  },
  {
    contentDetail: {
      body: [
        'Relax and watch the magic unfold as you',
        'enjoy your event hassle-free.'
      ],
      title: 'Celebrate!'
    },
    image: {
      alt: 'Celebrate',
      name: 'hdw-4.svg'
    },
    progressStatus: 100
  }
];

export const howDoesItWorkStepHeaderConfig = (activeStep) => [
  {
    className:
      activeStep === 0
        ? 'step-background remove-cursor'
        : 'step-background-inactive remove-cursor',
    label: 'Get Inspired'
  },
  {
    className:
      activeStep === 1
        ? 'step-background remove-cursor'
        : 'step-background-inactive remove-cursor',
    label: 'Consult with Experts'
  },
  {
    className:
      activeStep === 2
        ? 'step-background remove-cursor'
        : 'step-background-inactive remove-cursor',
    label: 'Book your Hafla'
  },
  {
    className:
      activeStep === 3
        ? 'step-background remove-cursor'
        : 'step-background-inactive remove-cursor',
    label: 'Celebrate!'
  }
];

export const splitArrayIntoChunks = ({ chunkSize }) =>
  _.chunk(topBrandLogos, chunkSize);

export const whyChooseHaflaServices = [
  {
    id: 1,
    image: '/why-choose-hafla-icons/price-guarantee-v1-icon.svg',
    name: 'Best Price Guarantee'
  },
  {
    id: 2,
    image: '/why-choose-hafla-icons/unlimited-supplies-v1-icon.svg',
    name: 'Unlimited Supplies'
  },
  {
    id: 3,
    image: '/why-choose-hafla-icons/trusted-quality-v1-icon.svg',
    name: 'Trusted Quality Partners'
  },
  {
    id: 4,
    image: '/why-choose-hafla-icons/customer-service-v1-icon.svg',
    name: 'Fast Customer Service'
  },
  {
    id: 5,
    image: '/why-choose-hafla-icons/quick-delivery-v1-icon.svg',
    name: 'Quick Delivery'
  }
];

export const eventGlimpsesList = [
  {
    id: 1,
    thumbnailUrl: '/event-glimpses/baby-showers-thumb.jpg',
    title: 'A Grand Gala Dinner',
    url: 'https://youtu.be/OkzXSfwu6nU'
  },
  {
    id: 2,
    thumbnailUrl: '/event-glimpses/kids-birthdays-thumb.jpg',
    title: 'A Luxury Yacht Family Day',
    url: 'https://youtu.be/EQmVuPhn6OE'
  },
  {
    id: 3,
    thumbnailUrl: '/event-glimpses/casual-get-together-thumb.jpg',
    title: 'Carnival Lunch Celebration',
    url: 'https://youtu.be/9pKV1mIkADc'
  },
  {
    id: 4,
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Dazzling LED Sangeet Night',
    url: 'https://youtu.be/gFm-Gh7wdo0'
  },
  {
    id: 5,
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Dazzling LED Sangeet Night',
    url: 'https://youtu.be/Y5pCGZHLKtg'
  },
  {
    id: 6,
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Dazzling LED Sangeet Night',
    url: 'https://youtu.be/UwNDXY18B6Q'
  }
];

export const featuredCategories = [
  {
    href: '/categories/event-equipment/exhibition-furniture',
    logoUrl: '/featured-categories/production.jpg',
    name: 'Production',
    sortOrder: 2
  },
  {
    href: 'https://book.hafla.com/pages/event-venues',
    logoUrl: '/featured-categories/venues.jpg',
    name: 'Venue',
    sortOrder: 1
  },
  {
    href: '/categories/event-equipment/event-furniture',
    logoUrl: '/featured-categories/rentals.jpg',
    name: 'Rentals',
    sortOrder: 3
  },
  {
    href: 'https://book.hafla.com/pages/decor-vertical?sort_by=best-selling&page=3&filter.p.m.custom.brand_name=Hafla&filter.v.option.delivery+location=Abu+Dhabi&utm_source=mk_test&utm_medium=mk_test&utm_content=mk_test',
    logoUrl: '/featured-categories/decor.jpg',
    name: 'Decor',
    sortOrder: 4
  },
  {
    href: 'https://book.hafla.com/pages/food-and-beverage',
    logoUrl: '/featured-categories/catering.jpg',
    name: 'Catering',
    sortOrder: 5
  },
  {
    href: '/categories/people-and-services',
    logoUrl: '/featured-categories/artists.jpg',
    name: 'Artists',
    sortOrder: 6
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export const trustedPartners = [
  {
    alt: 'atlantis',
    sortOrder: 1,
    url: '/trusted-partners/atlantis.svg'
  },
  {
    alt: 'marriott',
    sortOrder: 2,
    url: '/trusted-partners/marriott.svg'
  },
  {
    alt: 'jumeirah',
    sortOrder: 3,
    url: '/trusted-partners/jumeirah.svg'
  },
  { alt: 'fairmont', sortOrder: 4, url: '/trusted-partners/fairmont.svg' },
  { alt: 'hilton', sortOrder: 5, url: '/trusted-partners/hilton.svg' },
  { alt: 'raffles', sortOrder: 6, url: '/trusted-partners/raffles.svg' },
  { alt: 'rixos', sortOrder: 7, url: '/trusted-partners/rixos.svg' },
  { alt: 'radisson', sortOrder: 8, url: '/trusted-partners/radisson.svg' },
  { alt: 'habtoor', sortOrder: 9, url: '/trusted-partners/habtoor.svg' }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));
