import dynamic from 'next/dynamic';

import { SectionHeading } from '@/components/atomic/atoms';
import { eventGlimpsesList } from '@/services/homePage.service';

const VideoCard = dynamic(
  () => import('@/components/atomic/atoms/homePage/VideoCard'),
  {
    ssr: false
  }
);

const EventGlimpsesMobile = () => (
  <div className='bg-white pb-8 pr-3'>
    <SectionHeading
      {...{
        letterSpacing: '3px',
        title: 'A Glimpse into our Events',
        underline: true
      }}
    />
    <div className='flex ps-2.5 xxxs:ps-5 event-glimpses-column-container'>
      <div className='event-glimpses-grid-container flex-nowrap no-scrollbar items-center card-scroll'>
        {eventGlimpsesList.map(({ id, url }) => (
          <VideoCard
            key={id}
            {...{ url }}
          />
        ))}
      </div>
    </div>
  </div>
);

export default EventGlimpsesMobile;
