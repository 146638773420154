import Image from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { ReadMeArticle } from '@/components/atomic/atoms';

const Card = ({ title, imgSrc, linkUrl, type }) => {
  const router = useRouter();
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        if (linkUrl) router.push(linkUrl);
      }}
      className={`bg-white p-5 border w-1/4 flex-1 ml-2 shadow-lg mr-2 h-1/2 ${
        linkUrl ? 'cursor-pointer' : ''
      }`}
    >
      <div className='w-full h-80 relative overflow-hidden rounded-1.5'>
        <Image
          src={imgSrc}
          alt={title}
          className='collection-img'
          layout='fill'
        />
      </div>
      <h3
        className={`mt-5 py-1.5 text-17px text-nero font-Inter font-bold leading-5 text-left ${
          type !== 'blog' && 'text-center'
        }`}
      >
        {' '}
        {title}{' '}
      </h3>
      {type === 'blog' && <ReadMeArticle {...{ linkUrl }} />}
    </a>
  );
};

export default Card;
Card.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  type: PropTypes.string
};
